import React, { useEffect, useState } from "react";

const HubspotFormComet = ({ portalId, formId, loading }) => {
  const [isFormLoaded, setIsFormLoaded] = useState(false);

  useEffect(() => {
    // Check if the hbspt object is already available
    if (!window.hbspt) {
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        loadHubspotFormComet();
      };
    } else {
      loadHubspotFormComet(); // If already loaded, just create the form
    }
  }, [portalId, formId]);

  const loadHubspotFormComet = () => {
    window.hbspt.forms.create({
      portalId: portalId ? portalId : '23182726',
      formId: formId ? formId : 'dfa38579-9c57-4e76-8e3d-5fd33b0f232e',
      target: "#hubspot-form-container", // Ensure this matches your div ID
      onFormReady: function (form) {
        setTimeout(() => {
          const cometlyScript = document.createElement("script");
          cometlyScript.src =
            "https://t.cometlytrack.com/e?uid=f10b92-4503599670000002-1f23d3-s&cometlyref=hubspotmarketingform";
          cometlyScript.type = "text/javascript";
          form[0].appendChild(cometlyScript);

          const cometFunctions = document.createElement("script");
          cometFunctions.type = "text/javascript";
          cometFunctions.innerHTML = 'setTimeout(() => {comet("init");}, 400)';
          form[0].appendChild(cometFunctions);
        }, 300);
        
        // Set form loaded state to true
        setIsFormLoaded(true);
      },
    });
  };

  return (
    <div>
      {!isFormLoaded && loading} {/* Show loading text while form is not loaded */}
      <div id="hubspot-form-container"></div> {/* Form container */}
    </div>
  );
};

export default HubspotFormComet;